import { combineReducers } from "redux";
import { configReducer } from "../redux/reducers/config/getConfig";
import { spotsReducer } from "../redux/reducers/spots";
import { spotPopupReducer } from "../redux/reducers/spotPopup";
import { geolocateReducer } from "../redux/reducers/geolocate";

export const mainReducer = combineReducers({
  config: configReducer,
  spots: spotsReducer,
  spotPopup: spotPopupReducer,
  geolocate: geolocateReducer
});

// export default mainReducer;
