import React from "react";
import PropTypes from "prop-types";

import SMKHelpers from "../SMKHelpers";
import List from "./List";
import ListElement from "./ListElement";
import { faAngleRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class JobsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }
  toggleList = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  render() {
    const {
      feature,
      onClick,
      includePOIInClickCallback,
      searchString
    } = this.props;

    const poi = feature;
    let jobs = [...poi.properties.jobsList];

    jobs = SMKHelpers.filterJobs(jobs);

    if (!!searchString) {
      SMKHelpers.sortJobsByRanking(jobs);
      // console.log("--- sort by ranking");
    } else {
      SMKHelpers.sortJobs(jobs);
    }

    let toggleElement;
    if (jobs.length > 5) {
      let title;
      if (this.state.expanded === false) {
        jobs = jobs.slice(0, 5);
        title = "mehr ...";
      } else {
        title = "weniger ...";
      }
      toggleElement = (
        <button
          className="f6 link dim ba pv2 mb2 dib blue b--white underline"
          onClick={this.toggleList}
        >
          {title}
        </button>
      );
    }

    const listElements = jobs.map((job, index) => {
      let searchResultInfo;
      if (searchString.length > 0) {
        let matchedSearchResults = job.searchResults.matchedTerms.map(
          result => {
            return (
              <span key={result} className="ph1">
                {result}
              </span>
            );
          }
        );
        let unmatchedSearchResults = job.searchResults.unmatchedTerms.map(
          result => {
            return (
              <span key={result} className="ph1 strike">
                {result}
              </span>
            );
          }
        );
        const rankingInfo = <span key="ranking">Suchbegriffe: </span>;
        searchResultInfo = (
          <p className="mv0" style={{ fontSize: "0.75rem" }}>
            {[rankingInfo, ...matchedSearchResults, ...unmatchedSearchResults]}
          </p>
        );
      }

      return (
        <ListElement
          className="pointer jobListElement"
          key={`${index}-${job.id}`}
          onClick={() => {
            if (includePOIInClickCallback === true) {
              onClick(poi, job);
            } else {
              onClick(job);
            }
          }}
        >
          <FontAwesomeIcon
            className="icon absolute top-0 right-0 mr2 h-100 f4"
            icon={faAngleRight}
          />
          <b>{job.berufsbezeichnung} </b>
          <p className="mv0" style={{ fontSize: "0.75rem" }}>
            Anstellung: {job.anstellungsart}
          </p>
          {searchResultInfo}
        </ListElement>
      );
    });

    return (
      <List>
        {listElements}
        {toggleElement}
      </List>
    );
  }
}

export default JobsList;
