import { transparentize } from "polished";

const COLOR_VALUES = {
  green: {
    default: "#cde132"
  },
  blue: {
    default: "#1b375b",
    light: "#afb3c6"
  },
  grey: {
    default: "#f4f4f3"
  },
  black: {
    default: "#00000",
    light: "#333333"
  }
};
const SMK_THEME = {
  colors: {
    ...COLOR_VALUES,
    icon: COLOR_VALUES.blue.default,
    headline: COLOR_VALUES.blue.default,
    company_name: COLOR_VALUES.black.default,
    job_title: COLOR_VALUES.black.light,
    cluster_border: COLOR_VALUES.blue.default,
    cluster: COLOR_VALUES.blue.light,
    job_item_shadow: transparentize(0.16, COLOR_VALUES.blue.default),
    controls_background: COLOR_VALUES.grey.default,
    poi_border: transparentize(0.5, COLOR_VALUES.blue.default),
    ci_green: COLOR_VALUES.green.default,
    ci_blue: COLOR_VALUES.blue.default
  }
};

export default SMK_THEME;
