class SMKHelpers {
  static jobTypes = {
    Vollzeit: 1,
    "Befristeter Vertrag. Vollzeit": 2,
    "Vollzeit und Teilzeit": 3,
    Teilzeit: 4,
    "Befristeter Vertrag. Teilzeit": 5,
    "Freie Mitarbeit, Dienstvertrag": 6,
    Ferienjob: 7,
    "ehrenamtliche Tätigkeit": 8,
    Ausbildung: 9,
    "Duales Studium": 10,
    Trainee: 11,
    Praktikum: 12,
    Auslandspraktikum: 13,
    Schulpraktikum: 14,
    Werkstudententätigkeit: 15,
    "Abschlussarbeit / Doktorarbeit / Facharbeit": 16,
    Studienarbeit: 17,
    Refrendariat: 18,
    Volontariat: 19,
    "Freiwilliges Soziales Jahr": 20
  };

  static normalize(string) {
    return string.trim().toLowerCase();
  }

  static filterJobs(jobs) {
    let filteredJobs = jobs.filter(job => {
      return job.searchResults.passed;
    });

    return filteredJobs;
  }

  static sortJobs(jobs) {
    jobs.sort((a, b) => {
      const jobTypeA = SMKHelpers.jobTypes[a.anstellungsart];
      const jobTypeB = SMKHelpers.jobTypes[b.anstellungsart];

      if (parseFloat(jobTypeA) > parseFloat(jobTypeB)) {
        return 1;
      }

      if (parseFloat(jobTypeB) > parseFloat(jobTypeA)) {
        return -1;
      }

      return 0;
    });
  }
  static sortJobsByRanking(jobs) {
    jobs.sort((a, b) => {
      if (
        parseFloat(a.searchResults.ranking) <
        parseFloat(b.searchResults.ranking)
      )
        return 1;
      if (
        parseFloat(b.searchResults.ranking) <
        parseFloat(a.searchResults.ranking)
      )
        return -1;

      return 0;
    });
  }

  static findJobInSpotLayerByProffileJobId = (
    jobId,
    spotsData,
    urlParams = {}
  ) => {
    let foundJob;
    for (const spotLayer of spotsData) {
      for (const feature of spotLayer.featureCollection.features) {
        // const currentFeature = feature;
        for (const job of feature.properties.jobsList) {
          if (String(job.id) === String(jobId)) {
            console.log("--- found job with plz:", job.plz);
            if (urlParams.plz) {
              if (urlParams.plz === String(job.plz)) {
                foundJob = job;
              }
            } else {
              return job;
            }
          }
        }
      }
    }
    return foundJob;
  };

  static findCompanyByJobInFeatures = (job, spotsData) => {
    let foundCompany;
    for (const spotLayer of spotsData) {
      for (const feature of spotLayer.featureCollection.features) {
        if (
          String(feature.properties.companyProfile.company_id) ===
          String(job.company_id)
        ) {
          for (const j of feature.properties.jobsList) {
            if ( String(j.id) ===
            String(job.id)) {
              if (String(j.plz) ===
              String(job.plz)) {
                console.log("--- found job of company with matching plz", feature);
                return feature;
              }
              console.log("--- found job of company", feature);
              foundCompany = feature;
            }
          }
        }
      }
    }
    return foundCompany;
  };

  static applyFilterOnFeature(feature, filter) {
    feature.properties.filter = { ...feature.properties.filter, passed: true };
    if (!filter) {
      return feature;
    }

    const getAttribute = (obj, attribute) => {
      return obj[attribute];
    };

    const attributes = filter.attribute.split(".");

    let currentAttributeOfObj = getAttribute(feature, "properties");
    let count = 0;
    for (let attribute of attributes) {
      currentAttributeOfObj = getAttribute(currentAttributeOfObj, attribute);

      count = count + 1;
      if (count < attributes.length) {
        continue;
      } else {
        if (currentAttributeOfObj === filter.value) {
          return feature;
        }
      }

      feature.properties.filter = {
        ...feature.properties.filter,
        passed: false
      };
      return feature;
    }
  }

  static applySearchFilterOnFeature(feature, searchStr) {
    const getSearchResultsObject = () => {
      return {
        matchedTerms: [],
        unmatchedTerms: [],
        ranking: 0,
        passed: false
      };
    };
    let searchTerms = [];
    if (searchStr.length > 0) {
      searchTerms = this.normalize(searchStr).split(" ");
      // remove duplicates
      searchTerms = searchTerms.filter(
        (item, index) => searchTerms.indexOf(item) === index
      );
      // normalize terms
      searchTerms = searchTerms.map(term => {
        return SMKHelpers.normalize(term);
      });
    }
    // console.log("--- filterFeature searchTerms", searchTerms);

    // perform string matching on the following properties
    feature.properties.searchResults = getSearchResultsObject();

    // check if company matches search terms
    let texts = [
      feature.properties.companyProfile.name,
      feature.properties.companyProfile.plz
    ];
    // check how many of the search terms match
    const searchString = texts.join(" ").toLowerCase();
    for (const term of searchTerms) {
      if (searchString.includes(term)) {
        feature.properties.searchResults.matchedTerms.push(term);
      } else {
        feature.properties.searchResults.unmatchedTerms.push(term);
      }
    }

    if (feature.properties.searchResults.matchedTerms.length > 0) {
      feature.properties.searchResults.ranking =
        feature.properties.searchResults.matchedTerms.length /
        searchTerms.length;
      feature.properties.searchResults.passed = true;
    }

    // check all jobs for matching search terms
    for (let job of feature.properties.jobsList) {
      let texts = [
        feature.properties.companyProfile.name,
        feature.properties.companyProfile.plz
      ];
      job.searchResults = getSearchResultsObject();

      // skip matching if there are no search terms so the job passes filtering
      if (searchTerms.length === 0) {
        job.searchResults.passed = true;
        continue;
      }

      if (!!job.berufsbezeichnung) {
        texts.push(job.berufsbezeichnung);
      }
      if (!!job.anstellungsart) {
        texts.push(job.anstellungsart);
      }
      if (!!job.plz) {
        texts.push(job.plz);
      }
      if (job.tags) {
        for (const tag of job.tags) {
          if (!!tag) {
            texts.push(tag);
          }
        }
      }
      if (job.branchen) {
        for (const branche of job.branchen) {
          if (!!branche) {
            texts.push(branche);
          }
        }
      }
      if (job.bereiche) {
        for (const bereich of job.bereiche) {
          if (!!bereich) {
            texts.push(bereich);
          }
        }
      }

      // remove duplicates
      texts = texts.filter((item, index) => texts.indexOf(item) === index);

      // check how many of the search terms match
      const searchString = texts.join(" ").toLowerCase();
      // console.log("--- filterFeature: texts", texts);
      for (const term of searchTerms) {
        if (searchString.includes(term)) {
          // console.log(`--- ${term} is in ${job.id}: ${searchString}`);
          job.searchResults.matchedTerms.push(term);
        } else {
          job.searchResults.unmatchedTerms.push(term);
        }
      }

      // generate ranking
      if (job.searchResults.matchedTerms.length > 0) {
        job.searchResults.ranking =
          job.searchResults.matchedTerms.length / searchTerms.length;
        job.searchResults.passed = true;
        if (
          job.searchResults.ranking > feature.properties.searchResults.ranking
        ) {
          feature.properties.searchResults = { ...job.searchResults };
        }
      }
      // console.log("--- filterFeature: searchResults", job);
    }

    if (searchTerms.length === 0) {
      feature.properties.searchResults.passed = true;
    }

    return feature;
  }
}

export default SMKHelpers;
