import React from "react";
import styled from "styled-components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InlineBrowserWrapper = styled.div.attrs(props => ({
  className: "inlineBrowserWrapper absolute bottom-0 ma0 pa0 bn w-100 z-3"
}))`
  height: calc(100% - 35px);
  background-color: rgba(204, 205, 205, 0.85);
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  overscroll-behavior: none;
`;

const InlineBrowserIframe = styled.iframe.attrs(props => ({
  className: "db  pa0 bn w-100 z-3 bg-white"
}))`
  margin:0;
  margin-top: -60px;
  height: calc(100% + 60px);
  overflow-y: auto !important;
`;

const CloseButton = styled.button.attrs(props => ({
  className:
    "absolute bg-white mt1 z-4 bn f6 br-pill absolute circle w2 h2 shadow-1 flex items-center justify-center"
}))`
  color: ${props => props.theme.colors.icon};
  right: 10px;
  top: 58px;
`;

class InlineBrowser extends React.Component {
  iFrame = React.createRef();

  static jobsUlm = {
    urlIdentifier: "jobs-ulm.de/jobdetail_popup.php",
    minWidth: 997
  };

  adjustIframeWrapperOverflowSettingsForIos(iFrameWrapper) {
    if (/iPhone|iPad/i.test(navigator.userAgent)) {
      console.log("iFrameWrapper.style.cssText: ", iFrameWrapper.style.cssText);
      iFrameWrapper.style.cssText = "overflow-y: auto !important";
      console.log("iFrameWrapper.style.cssText: ", iFrameWrapper.style.cssText);
    }
  }

  resizeIframeToFitContent = () => {
    console.log("iframe - element ref: ", this.iFrame.current);
    console.log("resizeIframeToFitContent - started.");

    const iframeSrc = this.iFrame.current.src;
    const isUnresponsiveJobsUlmWebsite = iframeSrc.includes(
      InlineBrowser.jobsUlm.urlIdentifier
    );
    console.log("isUnresponsiveJobsUlmWebsite: ", isUnresponsiveJobsUlmWebsite);

    if (isUnresponsiveJobsUlmWebsite) {
      scaleIframeContentToFitIntoAvailableWidth(this.iFrame.current);
      // adjustIframeWrapperOverflowSettingsForIos(this.iFrame.current.parentNode);
    }

    // |-----START: (Private) Functions only available to and in support of the resizeIframeToFitContent() - function:
    // |
    // |
    function scaleIframeContentToFitIntoAvailableWidth(iframe) {
      const contentAvailableWidth = document.body.clientWidth;
      const contentAvailableHeight = document.body.clientHeight;
      console.log("contentAvailableWidth", contentAvailableWidth);
      console.log("contentAvailableHeight", contentAvailableHeight);

      let scaleRatio = contentAvailableWidth / InlineBrowser.jobsUlm.minWidth;
      scaleRatio = scaleRatio < 1 ? scaleRatio : 1;

      const reverseScaleRatioWidth = parseInt(
        contentAvailableWidth * (1 / scaleRatio)
      );
      const reverseScaleRatioHeight = parseInt(
        contentAvailableHeight * (1 / scaleRatio)
      );
      console.log("scaleRatio: ", scaleRatio);

      const cssStylesIframeScalingRatios = "-ms-transform: scale("
        .concat(scaleRatio, "); -moz-transform: scale(")
        .concat(scaleRatio, "); -o-transform: scale(")
        .concat(scaleRatio, "); -webkit-transform: scale(")
        .concat(scaleRatio, ");  transform: scale(")
        .concat(
          scaleRatio,
          "); -ms-transform-origin: 0 0;  -moz-transform-origin: 0 0; -o-transform-origin: 0 0; -webkit-transform-origin: 0 0;    transform-origin: 0 0;"
        );

      console.log(
        "cssStylesIframeScalingRatio: ",
        cssStylesIframeScalingRatios
      );

      iframe.style.cssText =
        iframe.style.cssText + cssStylesIframeScalingRatios;
      iframe.style.cssText =
        iframe.style.cssText +
        "width: " +
        reverseScaleRatioWidth +
        "px !important";
      iframe.style.cssText =
        iframe.style.cssText +
        "height: " +
        reverseScaleRatioHeight +
        "px !important";
    }
    // |
    // |
    // |-----END: Functions only available to the resizeIframeToFitContent() - function:
  };

  isJobsUlmUrl = url => {
    return url && url.includes(InlineBrowser.jobsUlm.urlIdentifier);
  };

  changeMetaViewportTag = contentString => {
    document.querySelector("meta[name='viewport']").content = contentString;
  };

  prepareIframeForJobsUlmUrls = url => {
    console.log();
    if (this.isJobsUlmUrl(url)) {
      console.log("InlineBrowser|url: ", url);
      const userAgent = window.navigator.userAgent;
      console.log("navigator.userAgent: ", userAgent);
      if (/Android/i.test(userAgent)) {
        console.log("Is Android Phone");
        // this.changeMetaViewportTag(
        //   "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=2.5, user-scalable=yes"
        // );
      }
      if (/iPhone|iPad/i.test(userAgent)) {
        console.log("Is iOs Phone");
        // this.changeMetaViewportTag(
        //   "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        // );
      }
    }
  };

  render() {
    const { url, handleClose, options, isLoadedInIframe } = this.props;

    if (!!url) {
      const httpsURL = url.replace("http:", "https:");
      return (
        <InlineBrowserWrapper>
          <InlineBrowserIframe
            ref={this.iFrame}
            src={httpsURL}
            frameBorder="0"
            onLoad={this.resizeIframeToFitContent}
          />

          {isLoadedInIframe === true && (
            <CloseButton
              onClick={e => {
                handleClose();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </CloseButton>
          )}
        </InlineBrowserWrapper>
      );
    } else {
      return null;
    }
  }

  componentDidUpdate() {
    if (!!this.props.url && /iPhone|iPad/i.test(navigator.userAgent)) {
      this.adjustIframeWrapperOverflowSettingsForIos(
        this.iFrame.current.parentNode
      );
    }
  }

  componentWillUnmount() {
    this.changeMetaViewportTag(
      "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
    );
  }

  componentDidMount() {
    this.changeMetaViewportTag(
      "width=device-width, initial-scale=1.0, minimum-scale=1.0, user-scalable=yes"
    );

    window.addEventListener("resize", () => {
      // console.log("window - resized!");
      if (!!this.iFrame.current) {
        this.resizeIframeToFitContent(this.iFrame.current);
      }
    });
  }
}

export default InlineBrowser;
