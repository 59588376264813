import { faAngleLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { distance, point } from "@turf/turf";
import styled from "styled-components";
import React from "react";
import JobsList from "../components/JobsList";
import CompanyProfileLink from "../components/CompanyProfileLink";

const CloseButton = styled.button.attrs(props => ({
  className:
    "closePoiListButton absolute link bn bg-transparent left-0 top-0 ttu mt1"
}))`
  font-size: 1.25rem;

  .icon {
    font-size: 1.5rem;
    vertical-align: text-top;
  }

  color: ${props => props.theme.colors.icon};
  .defaultHeight & {
    display: none;
  }
`;

const PopupWrapper = styled.section.attrs(props => ({
  className: ""
}))`
  hr {
    border-color: ${props => props.theme.colors.icon};
    border-top-style: none;
  }

  h3 {
  }

  .postal-code {
    color: ${props => props.theme.colors.black.light};
  }
  .sub-headline {
    color: ${props => props.theme.colors.headline};
  }
`;

const PoiPopupComponent = styled.section.attrs(props => ({
  className: "poiPopupComponent flex-grow-1 overflow-y-auto"
}))`
  -webkit-overflow-scrolling: touch;
`;

class POIPopup extends React.Component {
  state = {
    contentSections: {}
  };

  navigateToSpotLocation = (
    latitude = this.state.latitude,
    longitude = this.state.longitude,
    openInNewWindow = true
  ) => {
    window.open(
      `http://maps.apple.com/maps?q=${latitude},${longitude}`,
      openInNewWindow ? "_blank" : "_top"
    );
  };

  // createJobsList = (feature, onClick) => {
  //   const poi = feature;
  //   let jobs = [...poi.properties.jobsList];

  //   jobs = SMKHelpers.filterJobs(jobs);

  //   SMKHelpers.sortJobs(jobs);

  //   const listElements = jobs.map((job, index) => {
  //     return (
  //       <ListElement
  //         key={`${index}-${job.id}`}
  //         onClick={() => {
  //           onClick(job);
  //         }}
  //       >
  //         <FontAwesomeIcon
  //           className="fr absolute right-0 mr2 near-white h-100 f4"
  //           icon={faAngleRight}
  //         />
  //         <b>{job.berufsbezeichnung} </b>
  //         <b className="" style={{ fontSize: "0.75rem" }}>
  //           ( {job.anstellungsart} )
  //         </b>
  //       </ListElement>
  //     );
  //   });

  //   return <List>{listElements}</List>;
  // };

  onCompanyClick = e => {
    e.preventDefault();
    this.props.onCompanyWebProfileClick(this.props.selectedFeature);
  };

  getContent = () => {
    const { selectedFeature, geolocatePosition } = this.props;

    let distanceToGeolocateInfo = null;
    if (geolocatePosition !== null) {
      const { latitude, longitude } = geolocatePosition.coords;
      var from = point(selectedFeature.geometry.coordinates);
      var to = point([longitude, latitude]);
      var options = { units: "kilometres" };

      var distanceInfo = distance(from, to, options);

      distanceToGeolocateInfo = (
        <time className="f6 ttu tracked gray">
          Entfernung: {parseInt(distanceInfo * 1000, 10)} m
        </time>
      );
    }

    let pictureUrl = "http://tachyons.io/img/over-canvas.jpg";
    if (
      selectedFeature.properties.picture_url !== null &&
      selectedFeature.properties.picture_url !== undefined
    ) {
      pictureUrl = selectedFeature.properties.picture_url;
    }

    return (
      <PopupWrapper>
        <div className="mt1 mb3 mh3 pointer" onClick={this.onCompanyClick}>
          {selectedFeature.properties.picture_url && (
            <div className="mw5-ns">
              <img
                className="br2"
                src={selectedFeature.properties.picture_url}
                alt=""
              />
            </div>
          )}
          <h3 className="f5 lh-copy mv0 truncate">
            {selectedFeature.properties.companyProfile.name}
          </h3>
          <p className="postal-code mv1">
            PLZ: {selectedFeature.properties.companyProfile.plz}
          </p>
          <CompanyProfileLink
            onClick={e => {
              // e.preventDefault();
              this.onCompanyClick(e);
            }}
          />
        </div>
        <hr className="" />
        <div className="fl w-100 ph3">
          <h3 className="sub-headline f4 ttu mv2">Jobs & Ausbildungen</h3>
          <JobsList
            feature={selectedFeature}
            onClick={this.props.onJobClick}
            searchString={this.props.searchString}
          />
        </div>
      </PopupWrapper>
    );
  };

  render() {
    const { onSpotPopupClose, listStateWasClosed } = this.props;

    return (
      <>
        {!this.props.isInlineBrowserActive && (
          <CloseButton className="closeButton" onClick={onSpotPopupClose}>
            <span className="mr1 icon">
              <FontAwesomeIcon icon={faAngleLeft} />
            </span>
            {listStateWasClosed === true ? "Karte" : "Liste"}
          </CloseButton>
        )}
        <PoiPopupComponent>{this.getContent()}</PoiPopupComponent>
      </>
    );
  }
}

export default POIPopup;
