import { AddFeaturesToCollection } from "../../helpers/convert";

export const GET_SPOTS_REQUEST = "GET_SPOTS_REQUEST";
export const GET_SPOTS_SUCCESS = "GET_SPOTS_SUCCESS";
export const GET_SPOTS_FAILURE = "GET_SPOTS_FAILURE";

export const request = (resetPagination = false) => {
  return {
    type: GET_SPOTS_REQUEST,
    payload: {
      code: 200,
      resetPagination,
      timestamp: Date.now()
    }
  };
};

export const success = response => {
  // console.log("--- redux action success", response);
  return {
    type: GET_SPOTS_SUCCESS,
    payload: {
      code: 200,
      meta: response.meta,
      data: response.data,
      timestamp: Date.now()
    }
  };
};

export const failure = (code, errors) => {
  return {
    type: GET_SPOTS_FAILURE,
    payload: {
      code,
      errors,
      timestamp: Date.now()
    }
  };
};

export function receivedSpotLayerFeatures(spotLayer, features) {
  return function(dispatch, getState) {
    let existingSpotLayer = null;
    let currentData = [...getState().spots.data];
    for (const layer of currentData) {
      if (layer.name === spotLayer.name) {
        existingSpotLayer = layer;
        existingSpotLayer.featureCollection = AddFeaturesToCollection(
          features,
          existingSpotLayer.featureCollection
        );
        break;
      }
    }

    if (existingSpotLayer === null) {
      currentData = [
        ...currentData,
        {
          ...spotLayer,
          featureCollection: AddFeaturesToCollection(features)
        }
      ];
    }

    const res = {
      meta: {},
      data: currentData
    };
    dispatch(success(res));
  };
}
