import { GEOLOCATE_UPDATE, GEOLOCATE_END } from "../actions/geolocate";

const defaultState = {
  position: null
};

export const geolocateReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GEOLOCATE_UPDATE:
      return {
        ...state,
        position: action.payload.position
      };
    case GEOLOCATE_END:
      return {
        ...state,
        position: null
      };
    default:
      return state;
  }
};

// export default spotsReducer;
