
export const SPOT_POPUP_OPEN = "SPOT_POPUP_OPEN";
export const SPOT_POPUP_CLOSE = "SPOT_POPUP_CLOSE";


export const spotPopupOpen = (featureUUID) => {
  return {
    type: SPOT_POPUP_OPEN,
    payload: {
      uuid: featureUUID,
      timestamp: Date.now()
    }
  };
};

export const spotPopupClose = () => {
  return {
    type: SPOT_POPUP_CLOSE,
    payload: {      
      timestamp: Date.now()
    }
  };
};
