import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

// Get reference for the webapp root (html) element for reuse:
const rootElement = document.getElementById("app");
ReactDOM.render(<App />, rootElement);

// // Create a reusable render method that we can call more than once:
// const render = () => {
//   // Dynamically import our main App component, and render it
//   const App = require("./App").default;

//   ReactDOM.render(<App />, rootElement);
// };

// if (process.env.NODE_ENV !== "production") {
//   if (module.hot) {
//     module.hot.accept("./App", () => {
//       render();
//     });
//   }
// }

// render();
