import styled from "styled-components";

const SearchListInput = styled.input.attrs(props => ({
  className: "mapSearchInput border-box mb3 ph4 bn w-100"
}))`
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  border-radius: 36px;
  color: ${props => props.theme.colors.headline};
  background-color: ${props => props.theme.colors.controls_background};

  ::placeholder {
    color: ${props => props.theme.colors.headline};
    font-size: 16px;
  }
`;

export default SearchListInput;
