import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Hammer from "hammerjs";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResizerWrapper = styled.div.attrs(props => ({
  className: "absolute resizer flex flex-shrink-0 justify-center relative pt2"
}))`
  top: 0;
  width: 30%;
  left: 35%;
  height: 45px;
  margin-top: -8px;
  .resizerIcon {
    color: ${props => props.theme.colors.icon};
    height: 36px;
    font-size: 18px;
  }
`;

class Resizer extends React.Component {
  static propTypes = {
    sizes: PropTypes.object.isRequired,
    handleResize: PropTypes.func.isRequired,
    initialSize: PropTypes.string
  };

  resizeElement = React.createRef();

  shouldComponentUpdate() {
    return false;
  }

  sizeUp = () => {
    console.log("C:Resizer|M:sizeUp - :", this.props.sizes);
    console.log("this.props.sizeIndex: ", this.props.sizeIndex);
    console.log("this.props.sizes", this.props.sizes);

    const sizesKeys = Object.keys(this.props.sizes);
    if (this.props.sizeIndex < sizesKeys.length - 1) {
      this.props.handleResize(sizesKeys[this.props.sizeIndex + 1]);
    }
  };

  sizeDown = () => {
    console.log("C:Resizer|M:sizeDown - :", this.props.sizes);
    console.log("this.props.sizeIndex: ", this.props.sizeIndex);
    console.log("this.props.sizes", this.props.sizes);

    const sizesKeys = Object.keys(this.props.sizes);
    if (this.props.sizeIndex > 0) {
      this.props.handleResize(sizesKeys[this.props.sizeIndex - 1]);
    }
  };

  sizeToggle = () => {
    console.log("C:Resizer|M:sizeToggle - :", this.props.sizes);

    const sizesKeys = Object.keys(this.props.sizes);
    if (this.props.sizeIndex === sizesKeys.length - 1) {
      this.props.handleResize(sizesKeys[this.props.sizeIndex - 1]);
    } else {
      this.props.handleResize(sizesKeys[this.props.sizeIndex + 1]);
    }
  };

  componentDidMount() {
    console.log("C:Resizer|M:componentDidMount - this.props", this.props);
    // Initialize Hammer on Resizer element:
    const hammer = new Hammer(this.resizeElement.current);

    // Config hammer wrapper to process vertical directions:
    hammer.get("swipe").set({ direction: Hammer.DIRECTION_VERTICAL });

    hammer.on("swipeup", event => {
      console.log("swipeup");
      event.preventDefault();
      this.sizeUp();
    });

    hammer.on("swipedown", event => {
      console.log("swipedown");
      event.preventDefault();
      this.sizeDown();
    });

    hammer.on("tap", event => {
      console.log("tap");
      event.preventDefault();
      this.sizeToggle();
    });
  }

  render() {
    console.log("C:Resizer|M:render - this.props:", this.props);
    return (
      <ResizerWrapper ref={this.resizeElement} onClick={event => {console.log("onclick resizer!")}}>
        <FontAwesomeIcon className="resizerIcon" icon={faGripLines} />
      </ResizerWrapper>
    );
  }
}

export default Resizer;
