// import { get } from "../../../api/api";

export const GET_CONFIG_REQUEST = "GET_CONFIG_REQUEST";
export const GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";
export const GET_CONFIG_FAILURE = "GET_CONFIG_FAILURE";

export const request = (resetPagination = false) => {
  return {
    type: GET_CONFIG_REQUEST,
    payload: {
      code: 200,
      resetPagination,
      timestamp: Date.now()
    }
  };
};

export const success = response => {
  return {
    type: GET_CONFIG_SUCCESS,
    payload: {
      code: 200,
      meta: response.meta,
      data: response.data,
      timestamp: Date.now()
    }
  };
};

export const failure = (code, errors) => {
  return {
    type: GET_CONFIG_FAILURE,
    payload: {
      code,
      errors,
      timestamp: Date.now()
    }
  };
};

export function getConfig(configId) {
  return function(dispatch, getState) {
    if (getState().config.meta.isFetching === true) {
      return;
    }

    dispatch(request());

    const url = `${process.env.PUBLIC_URL}/config/${configId}.json`;
    console.log("--- getConfig ", url);
    fetch(url)
      .then(function(response) {
        return response.json();
      })
      .then(function(json) {
        // console.log("starting getConfig", json);

        // let res = JSON.parse(json);
        let res = json;

        // console.log("next getConfig", res);

        if (res.meta.code === 200) {
          // console.log("status 200 getConfig", res);
          dispatch(success(res));
        } else {
          dispatch(failure(res.meta.code, res.meta.errors));
        }
        // return res;
      })
      .catch(function(ex) {
        // console.log("parsing failed", ex);
        dispatch(
          failure(400, [
            { attribute: null, message: "Something went wrong", id: -1 }
          ])
        );
        // return ex;
      });
  };
}
