import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ThemeProvider } from "styled-components";
import { THEME } from "./style/theme";

import Map from "./components/map/Map";
import { GlobalStyle } from "./style/theme";

import "../node_modules/tachyons/css/tachyons.css";
import "./App.css";

// proffile.de/
// proffile.de/eine-firma-2.1234
// proffile.de/jobs-ulm
// proffile.de/jobs-ulm/eine-firma-2.1234

//:optionalPath(*.*)?|(/:configId/:optionalPath(*.*))

//<Route exact path="/:configId([^\/]+[^\.]+.*)?" component={Map} />

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={THEME}>
        <Provider store={store}>
          <GlobalStyle />
          <BrowserRouter basename="/maps">
            <Switch>
              <Route exact path="/:optionalPath([^\/]+\..*)/" component={Map} />
              <Route exact path="/:configId/:optionalPath/" component={Map} />
              <Route exact path="/:configId?" component={Map} />
            </Switch>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
