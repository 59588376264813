import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CompanyProfilIcon from "../assets/images/unternehmensprofil.png";

const CompanyProfileLinkStyled = styled.a.attrs(props => ({
  className: "link underline mt2 db"
}))`
  color: ${props => props.theme.colors.icon};
  .icon {
    color: ${props => props.theme.colors.icon};
  }
`;

const CompanyProfileLink = ({ onClick }) => {
  return (
    <CompanyProfileLinkStyled onClick={onClick} href="#">
      <img src={CompanyProfilIcon} style={{ height: "24px" }} className="icon mr1" />
      Unternehmensprofil
    </CompanyProfileLinkStyled>
  );
};

export default CompanyProfileLink;
