import { GET_SPOTS_REQUEST, GET_SPOTS_SUCCESS, GET_SPOTS_FAILURE } from "../actions/spots";

const Meta = params => {
  return {
    limit: 100,
    count: 100,
    lastChange: 0,
    isFetching: false,
    invalidate: false,
    ...params
  };
};

const defaultState = {
  meta: Meta(),
  data: []
};


export const spotsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_SPOTS_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          errors: null,
          isFetching: true
        }
      };
    case GET_SPOTS_SUCCESS:
      return {
        ...state,
        meta: {
          ...action.payload.meta,
          lastChange: action.payload.timestamp,
          isFetching: false
        },
        data: [
          ...action.payload.data,
        ]
      };
    case GET_SPOTS_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          errors: action.payload.errors
        }
      };
    default:
      return state;
  }
};

// export default spotsReducer;
