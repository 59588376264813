import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import ProffileLogoIcon from "../../plugins/proffile/assets/images/proffile-logo-icon.png";
import SearchIcon from "../../plugins/proffile/assets/images/search-icon.svg";
import { faList, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MenuWrapper = styled.nav.attrs(props => ({
  className:
    "menuComponent absolute top-0 w-100 flex justify-between items-center z-5"
}))`
  height: 55px;
  background-color: ${props =>
    props.backgroundColor || "unset"};
`;

const LeftToolbar = styled.div.attrs(props => ({
  className: "leftToolbar"
}))`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-width: 90px;
  justify-content: flex-start;
`;
const RightToolbar = styled.div.attrs(props => ({
  className: "rightToolbar"
}))`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-width: 90px;
  justify-content: flex-end;
`;

const Logo = styled.a.attrs(props => ({
  className: "logo",
  href: props.logoHref || ""
}))`
  height: 100%;
  flex-basis: 190px;
  min-width: 160px;
  flex-shrink: 1;
  cursor: pointer;
  ${props =>
    props.logoImage &&
    css`
      background-image: url(${props.logoImage || ""});
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    `};
`;

const LogoIcon = styled.a`
  display: block;
  height: 28px;
  width: 28px;
  background-image: url(${ProffileLogoIcon});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 0.44rem;
`;

const NavbarAction = styled.button`
  background-color: transparent;
  box-shadow: none;
  border: none;
  display: block;
  height: 28px;

  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 0.44rem;

  ${props =>
    props.icon &&
    css`
      background-image: url(${props.icon});
      width: 28px;
    `}

  @media screen and (max-width: 480px) {
    margin-right: 0.33rem;
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

// "SFCName" stands for "Stateless Functional Component" - Name and is just a descriptive placeholder name:
const Menu = props => {
  // Place for some initial calculation or data wrangling with props and such ...
  const {
    mapUi,
    backToMapVisible,
    backToMapAction,
    focusSearchInputAction,
    toggleListAction
  } = props;

  // Return the ui - elements here:
  return (
    <MenuWrapper
      backgroundColor={mapUi && mapUi.colors && mapUi.colors.background}
    >
      <LeftToolbar>
        {backToMapVisible === true && (
          <NavbarAction
            action="backToMap"
            onClick={backToMapAction}
            className="flex items-center"
            style={{ fontSize: "1.25rem", textTransform: "uppercase" }}
          >
            <span className="mr1 icon">
              <FontAwesomeIcon
                icon={faAngleLeft}
                style={{ fontSize: "1.5rem", verticalAlign: "text-top" }}
              />
            </span>
            Karte
          </NavbarAction>
        )}
      </LeftToolbar>
      <Logo
        logoImage={mapUi && mapUi.logo && mapUi.logo.image}
        logoHref={mapUi && mapUi.logo && mapUi.logo.href}
      ></Logo>
      <RightToolbar>
        <NavbarAction
          action="searchPois"
          icon={SearchIcon}
          onClick={focusSearchInputAction}
          style={{ marginRight: "0.22rem" }}
        />
        <NavbarAction action="showList" onClick={toggleListAction}>
          <FontAwesomeIcon icon={faList} />
        </NavbarAction>
        <LogoIcon
          href="https://www.proffile.de/impressum-datenschutz.html"
          target="_blank"
        />
      </RightToolbar>
    </MenuWrapper>
  );
};

Menu.propTypes = {
  // You can declare that a prop is a specific JS primitive (optionally):
  // <propertyName>: PropTypes.array|bool|func|number|object|string|symbol(.isRequired),
  // <propertyName>: PropTypes.array|bool|func|number|object|string|symbol(.isRequired),
  // e.g.:
  // children: React.PropTypes.string,
};

Menu.defaultProps = {
  // <propertyName>: <defaultValue>,
  // <propertyName>: <defaultValue>,
  // e.g.:
  // children: 'Hello World!',
};

export default Menu;
