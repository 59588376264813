
export const GEOLOCATE_UPDATE = "GEOLOCATE_UPDATE";
export const GEOLOCATE_END = "GEOLOCATE_END";


export const geolocateUpdate = (position) => {
  return {
    type: GEOLOCATE_UPDATE,
    payload: {
      position,
      timestamp: Date.now()
    }
  };
};

export const geolocateEnd = () => {
  return {
    type: GEOLOCATE_END,
    payload: {      
      timestamp: Date.now()
    }
  };
};
