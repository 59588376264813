import React from "react";
import styled from "styled-components";

const StickyOverlayWrapper = styled.div.attrs(props => ({
  className: "stickyOverlayWrapper absolute shadow-1 flex justify-center itmes-center"
}))`
  z-index: 101;
  height: 80px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(42, 56, 95, 0.7);
  border-top: 10px solid #bccf13;

  a {
    width: 80px;
    height: 80px;
    display: block;
    margin: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
`;

const StickyOverlay = ({options}) => {
  console.log("C:StickyOverlay - options");
  if (
    /iPhone|iPad/i.test(window.navigator.userAgent) &&
    !!options["job"] &&
    !!options["job"].fl_link
  ) {
    return (
      <StickyOverlayWrapper>
        <a
          className="mh1"
          href={options["job"].fl_link}
          alt="Link zur Kurzberwerbung"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            backgroundImage: `url(https://www.proffile.de/_Resources/Persistent/df78760270d3e178e0ac6ab289fbc3209cc27651/Fastlane_Logo_weiss.png)`
          }}
        >
          &nbsp;
        </a>
        {!!options["job"].contact_phone && (
          <a
            className="mh1"
            href={`tel:${options["job"].contact_phone}`}
            alt="Telefonnummer"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundImage: `url(https://www.proffile.de/_Resources/Persistent/80d34db36522af315a0b6bd74c988105c8e2009a/Telefonsymbol.png)`
            }}
          >
            &nbsp;
          </a>
        )}
      </StickyOverlayWrapper>
    );
  }

  return null;
};

export default StickyOverlay;
