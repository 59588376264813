// input: Spot[], specify field name to look for lat and long
export const SpotsToGeoJSON = (spots, latField = 'lat', lngField = 'lon') => {
  // pass a function to map
  const features = spots.map(spot => {

    let pictureUrlThumb = null;
    let pictureUrlThumbKeepRatio = null;
    if (spot.picture_url !== null && spot.picture_url !== undefined) {
      pictureUrlThumb = spot.picture_url.replace(/normal_/i, "thumb_");
      pictureUrlThumbKeepRatio = spot.picture_url.replace(/normal_/i, "thumb_keep_ratio_");
    }
    return  {
      type: "Feature", 
      properties: {
        ...spot,
        picture_url_thumb: pictureUrlThumb,
        picture_url_thumb_keep_ratio: pictureUrlThumbKeepRatio
      }, 
      geometry: { 
        type: "Point", 
        coordinates: [ spot[lngField], spot[latField], 0.0 ] 
      } 
    };
  });

  return features;
};

export const GetEmptyGeoJSONFeatureCollection = () => {
    return {
      type: "FeatureCollection",
      crs: { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
      features: []
    }
}

export const AddFeaturesToCollection = (features, featureCollection) => {
    if (featureCollection === undefined || featureCollection === null) {
      featureCollection = GetEmptyGeoJSONFeatureCollection();
    }
      
    featureCollection = {
      ...featureCollection,
      features: [...featureCollection.features, ...features]
    };

    return { ...featureCollection };
}