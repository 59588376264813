import styled from "styled-components";

import { ellipsis } from "polished";

const ListElement = styled.li.attrs(props => ({
  className: "bg-white pv2 ph3 mv2 relative"
}))`
  color: ${props => props.theme.colors.black.light};
  font-size: 14px;
  line-height: 20px;
  min-height: 32px;
  border-radius: 16px;
  ${ellipsis()};
  width: 100%;
  box-shadow: 0px 0px 4px ${props => props.theme.colors.job_item_shadow};
  white-space: normal;
  .icon {
    color: ${props => props.theme.colors.icon};
  }
`;

export default ListElement;
