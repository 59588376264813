import styled from "styled-components";

// import MapControlFullscreenStyle from "../../plugins/BUGA/components/MapControlFullscreenStyle";
// import MapControlZoomStyle from "../../plugins/BUGA/components/MapControlZoomStyle";
// import MapControlLocateStyle from "../../plugins/BUGA/components/MapControlLocateStyle";
// import MapControlCameraStyle from "../../plugins/BUGA/components/MapControlCameraStyle";
// import MapControlListStyle from "../../plugins/BUGA/components/MapControlListStyle";
// import MapControlCompassStyle from "../../plugins/BUGA/components/MapControlCompassStyle";

export const SearchInput = styled.input.attrs({
  className: "mapSearchInput",
  type: "search"
})`
  position: absolute;
  right: 65px;
  box-sizing: border-box;
  margin-top: 0.33rem;
  font-size: 13px;
  height: 45px;
  width: 175px;
  line-height: 45px;
  padding: 0 1rem;
  color: rgb(77, 77, 77);
  border: 4px solid rgba(92, 161, 57, 0.29);
  background-color: #ffffff;
  border-radius: 45px;
  ::placeholder {
    color: rgb(204, 204, 204);
    font-style: italic;
    font-size: 13px;
  }
`;

export const MapContainer = styled.div.attrs({
  className: "mapContainer"
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  .mapboxgl-ctrl-top-left--custom {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;

    .mapboxgl-ctrl-group {
      background-color: unset;
      box-shadow: unset;
      width: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0.33rem;

      .cameraButton {
      }

      .listButton {
      }
    }
  }
`;

export const MapboxWrapper = styled.div.attrs({
  className: "mapboxWrapper"
})`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;

  /* #TODO: Add styles for buga temporarily to every map. Should go into config. */
  .markerPhoto {
    &.markerPhotoArrow:after,
    &.markerPhotoArrow:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &.markerPhotoArrow:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 10px;
      margin-left: -10px;
    }
    &.markerPhotoArrow:before {
      border-color: rgba(194, 225, 245, 0);
      border-top-color: ${props => props.theme.colors.poi_border};
      border-width: 16px;
      margin-left: -16px;
    }
  }

  .mapboxgl-ctrl-top-right {
    .mapboxgl-ctrl-group {
      background-color: unset;
      box-shadow: unset;
      width: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0.33rem;
    }
    .mapboxgl-ctrl-fullscreen.mapboxgl-ctrl-icon {
    }
  }
  .mapboxgl-ctrl-bottom-left {
    bottom: 76px;
  }
  .mapboxgl-ctrl-bottom-right {
    bottom: 76px;

    .mapboxgl-ctrl-group {
      background-color: unset;
      box-shadow: unset;
      width: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0.33rem;
      bottom: 66px;
    }
    .mapboxgl-ctrl-zoom-in.mapboxgl-ctrl-icon {
    }
    .mapboxgl-ctrl-zoom-out.mapboxgl-ctrl-icon {
    }
    .mapboxgl-ctrl-geolocate.mapboxgl-ctrl-icon {
    }
    .mapboxgl-ctrl-compass.mapboxgl-ctrl-icon {
    }
  }
`;
