import { SPOT_POPUP_OPEN, SPOT_POPUP_CLOSE } from "../actions/spotPopup";

const defaultState = {
  selectedFeature: null,
  uuid: null
};

export const spotPopupReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SPOT_POPUP_OPEN:
      return {
        ...state,
        selectedUUID: action.payload.uuid
      };
    case SPOT_POPUP_CLOSE:
      return {
        ...state,
        selectedUUID: null
      };
    default:
      return state;
  }
};

// export default spotsReducer;
