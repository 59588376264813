import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ClearSearchIcon from "../assets/images/close-icon-inverted.svg";
import SearchIcon from "../assets/images/search-icon.svg";
import CompanyProfileLink from "../components/CompanyProfileLink";
import JobsList from "../components/JobsList";
// import ListElement from "./components/ListElement";
import SearchListInput from "./components/SearchListInput";

const ClearSearchStringButton = styled.button.attrs(props => ({
  className: "absolute"
}))`
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  background-image: url(${ClearSearchIcon});
  background-size: contain;
  background-repeat: no-repeat;
  border-style: none;
  background-color: transparent;
`;

const SearchListInputWrapper = styled.div.attrs(props => ({
  className: "bg-white w-100 ph4 flex flex-shrink-0 relative"
}))``;

const SearchListControls = styled.div.attrs(props => ({
  className: "relative bn w-100"
}))`
  &:after {
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    width: 20px;
    height: 20px;
    background-image: url(${SearchIcon});
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 3;
  }
`;

// import CompanyProfilIcon from "../assets/images/unternehmensprofil.png";

// const CompanyProfilLink = styled.a.attrs(props => ({
//   className: "link underline mt1 mh1 "
// }))`
//   color: ${props => props.theme.colors.icon};
//   .icon {
//     color: ${props => props.theme.colors.icon};
//   }
// `;

const PoiListComponent = styled.section.attrs(props => ({
  className: "poiListComponent flex flex-column overflow-hidden"
}))`
  -webkit-overflow-scrolling: touch;
`;

const CloseButton = styled.button.attrs(props => ({
  className:
    "closePoiListButton absolute link bn bg-transparent left-0 top-0 ttu mt1"
}))`
  font-size: 1.25rem;

  .icon {
    font-size: 1.5rem;
    vertical-align: text-top;
  }

  color: ${props => props.theme.colors.icon};
  .defaultHeight & {
    display: none;
  }
`;

const ResultsInsideMapBounds = styled.span.attrs(props => ({
  className: "pl1 pr1 pt1 pb1"
}))`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  border-radius: 24px;
  line-height: normal;
  color: ${props => props.theme.colors.headline};
`;

const ResultsOutsideMapBounds = styled.span.attrs(props => ({
  className: "pl2 pr2 pt1 pb1"
}))`
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: inherit;
  border-radius: 24px;
  line-height: normal;
  color: ${props => props.theme.colors.headline};
  background-color: ${props => props.theme.colors.controls_background};
  border-radius: 24px;
`;

class POIList extends React.Component {
  static propTypes = {
    allPOIs: PropTypes.array,
    renderedPOIUUIDs: PropTypes.array,
    onZoomToFitAllPOIs: PropTypes.func,
    onCompanyWebProfileClick: PropTypes.func.isRequired,
    onFeatureClick: PropTypes.func,
    onJobClick: PropTypes.func,
    onSearchInputFocus: PropTypes.func,
    onClose: PropTypes.func,
    searchString: PropTypes.string,
    handleSearchUpdate: PropTypes.func,
    headerTitle: PropTypes.string
  };

  //   componentWillReceiveProps(nextProps) {
  //   }

  //   componentDidUpdate() {
  //   }

  //   componentDidMount() {
  //   }

  getPOIElementTitle = poi => {
    return `ID: ${poi.properties.companyProfile.company_id}, Name: ${poi.properties.companyProfile.name}, Location: ${poi.properties.location.geo_x}, ${poi.properties.location.geo_y}, `;
  };

  onJobClick = (feature, job) => {
    // return;
    this.props.onJobClick(job);
  };

  renderClearSearchStringButton = searchString => {
    if (!!searchString) {
      return (
        <ClearSearchStringButton
          onClick={() => this.props.handleSearchUpdate("")}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    const {
      allPOIs,
      renderedPOIUUIDs,
      onFeatureClick,
      onSearchInputFocus,
      headerTitle,
      isInlineBrowserActive
    } = this.props;

    // console.log("--- allPOIs.length", allPOIs.length);
    // let distanceToGeolocateInfo = null;
    // if (geolocatePosition !== null) {
    //   var from = point(selectedFeature.geometry.coordinates);
    //   var to = point([geolocatePosition.coords.longitude, geolocatePosition.coords.latitude]);
    //   var options = {units: 'kilometres'};

    //   var distanceInfo = distance(from, to, options);
    //   console.log("distanceInfo", distanceInfo);

    //   console.log("feature coordinates", selectedFeature.geometry.coordinates);

    //   distanceToGeolocateInfo = <h6 className="f4 fw4 i lh-title mt0">
    //     Entfernung: {parseInt(distanceInfo * 1000, 10)} m
    //   </h6>
    // }

    // let naviLink = "http://maps.google.com/maps?q=";
    // if (navigator.userAgent.match(/(iPad|iPhone|iPod|Mac)/g)) {
    //   naviLink = "maps://maps.apple.com/?q=";
    // }

    let pois = [];
    let poisOutsideMapBounds = [];

    for (let poi of allPOIs) {
      if (renderedPOIUUIDs.includes(poi.properties.uuid)) {
        pois.push(poi);
      } else {
        poisOutsideMapBounds.push(poi);
      }
    }
    pois.sort((a, b) => {
      if (
        parseInt(a.properties.searchResults.ranking) <
        parseInt(b.properties.searchResults.ranking)
      )
        return 1;
      if (
        parseInt(b.properties.searchResults.ranking) <
        parseInt(a.properties.searchResults.ranking)
      )
        return -1;

      return 0;
    });

    const listItems = pois.map((feature, index) => {
      // const openNaviLink = `${naviLink}${feature.geometry.coordinates[1]},${feature.geometry.coordinates[0]}`;

      return (
        <article
          key={feature.properties.uuid}
          className="pv2 w-100 pointer"
          href="#0"
        >
          <div className="mv2 mh4 ph2">
            {feature.properties.picture_url && (
              <div
                className="mw5-ns"
                onClick={() => {
                  onFeatureClick(feature);
                }}
              >
                <img
                  className="br2"
                  src={feature.properties.picture_url}
                  alt=""
                />
              </div>
            )}
            <h3
              className="f5 lh-copy mb1 mt2 truncate"
              onClick={() => {
                onFeatureClick(feature);
              }}
            >
              {feature.properties.companyProfile.name}
            </h3>
            <CompanyProfileLink
              onClick={e => {
                e.preventDefault();
                console.log("--- click");
                this.props.onCompanyWebProfileClick(feature);
              }}
            />
          </div>
          <div className="mh4 mv0">
            <JobsList
              feature={feature}
              onClick={this.onJobClick}
              includePOIInClickCallback={true}
              searchString={this.props.searchString}
            />
          </div>
        </article>
      );
    });

    let searchResultInfo = null;
    if (!!this.props.searchString) {
      let searchResultLinks = [];
      if (pois.length > 0) {
        searchResultLinks.push(
          <ResultsInsideMapBounds key="results-in-bounds" className="f6 gray">
            {`${pois.length} Ergebnis(se) im Kartenbereich`}
          </ResultsInsideMapBounds>
        );
      }
      if (poisOutsideMapBounds.length > 0) {
        searchResultLinks.push(
          <ResultsOutsideMapBounds key="results-outter-bounds" className="">
            <button
              className="f6 bg-transparent bw0 link underline blue tl"
              onClick={e => {
                this.props.onZoomToFitAllPOIs();
              }}
            >
              {`${poisOutsideMapBounds.length} Ergebnis(se) außerhalb des Kartenbereichs
              anzeigen`}
            </button>
          </ResultsOutsideMapBounds>
        );
      }
      if (pois.length === 0 && poisOutsideMapBounds.length === 0) {
        searchResultInfo = (
          <div className="searchResultInfo bg-white w-100 ph4 flex flex-shrink-0 mb2">
            {"Die Suche ergab keine Treffer"}
          </div>
        );
      } else {
        searchResultInfo = (
          <div className="searchResultInfo bg-white w-100 ph4 flex flex-shrink-0 mb2">
            {searchResultLinks}
          </div>
        );
      }
    }

    return (
      <PoiListComponent>
        {/* <button
          className="f6 link dim br3 ba b--white ph3 pv2 mb2 dib black"
          onClick={onClose}
          style={{ position: "absolute", right: "20px", top: "10px" }}
        >
          x
        </button> */}
        {!isInlineBrowserActive && (
          <CloseButton className="" onClick={this.props.onClose}>
            <span className="mr1 icon">
              <FontAwesomeIcon icon={faAngleLeft} />
            </span>
            {headerTitle}
          </CloseButton>
        )}
        <SearchListInputWrapper>
          <SearchListControls>
            <SearchListInput
              placeholder="Jobs, Ausbildungen & Firmen in Deiner Region"
              value={this.props.searchString}
              onChange={e => this.props.handleSearchUpdate(e.target.value)}
              onFocus={onSearchInputFocus}
            />
            {this.renderClearSearchStringButton(this.props.searchString)}
          </SearchListControls>
        </SearchListInputWrapper>
        {searchResultInfo}
        <article className="h-100 overflow-y-auto flex-shrink-1">
          {listItems}
        </article>
      </PoiListComponent>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(POIList);
