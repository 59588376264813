import MapboxLanguage from "@mapbox/mapbox-gl-language";
import mapboxgl from "mapbox-gl";
import queryString from "query-string";
import React, { Component } from "react";
import Div100vh from "react-div-100vh";
import styled from "styled-components";
import { connect } from "react-redux";
import POIList from "../../plugins/proffile/POIList/POIList";
import POIPopup from "../../plugins/proffile/POIPopup/POIPopup";
import InlineBrowser from "../../plugins/proffile/InlineBrowser/InlineBrowser";
import StickyOverlay from "../../plugins/proffile/InlineBrowser/StickyOverlay";
import ResizeableContentArea, {
  HEIGHTS
} from "../../plugins/proffile/ResizableContentArea/ResizableContentArea";
import UEMarker from "../../plugins/uebermaps/Markers/UEMarker";
import Menu from "./Menu";
import { getConfig } from "../../redux/actions/config/getConfig";
import { geolocateUpdate } from "../../redux/actions/geolocate";
import { spotPopupClose, spotPopupOpen } from "../../redux/actions/spotPopup";
import { receivedSpotLayerFeatures } from "../../redux/actions/spots";
import { MapboxWrapper, MapContainer } from "./Style";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapLogoIcon from "../../plugins/proffile/assets/images/proffile-logo.svg";

import SMKHelpers from "../../plugins/proffile/SMKHelpers";
import { THEME } from "../../style/theme";

const MapLogo = styled.a.attrs(props => ({
  className: "absolute top-0 right-0 z-5 pointer mr1 mt1"
}))`
  width: 36px;
  height: 36px;
  background-image: url(${MapLogoIcon});
  background-size: contain;
`;

const MapControl = styled.div`
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
`;

const MapControlButton = styled.button`
  width: 30px;
  height: 30px;
  display: block;
  padding: 0;
  outline: none;
  border: 0;
  box-sizing: border-box;
  background-color: transparent;
  color: black;
  cursor: pointer;
`;

class Map extends Component {
  attachMapControlsDomRef = React.createRef();

  spotLayerHandler = {};
  spotLayerHandlerNameToUrl = {};
  mapContainerRef = React.createRef();

  map = null;
  markers = {};
  spiderfiedMarkers = [];
  updateMarkersTimer = null;

  getEntityFromOptionalUrlPath = () => {
    const opts = this.props.match.params;
    if (opts.optionalPath) {
      const optionalPathElements = opts.optionalPath.split("-");
      const entityElements = optionalPathElements[
        optionalPathElements.length - 1
      ].split(".");
      return {
        type: entityElements[0],
        id: entityElements[1].replace("/", "")
      };
    }
    return null;
  };

  getLayersFinishedLoadingAction = () => {
    const entity = this.getEntityFromOptionalUrlPath();
    if (entity) {
      switch (entity.type) {
        case "1":
          return {
            type: "openEntity",
            entity,
            spotLayer: 0,
            spotId: 1
          };
        case "2":
          return {
            type: "fitAll",
            entity
          };
        default:
          break;
      }
    }

    return null;
  };

  constructor(props) {
    super(props);
    // const opts = this.props.match.params;
    const parsedParams = queryString.parse(this.props.location.search);
    // console.log("--- url opts: ", opts);
    console.log("--- url query-strings: ", parsedParams);

    let filter = null;

    let currentListHeight = HEIGHTS.DEFAULT;
    const entity = this.getEntityFromOptionalUrlPath();
    console.log("--- our entity", entity);
    if (entity) {
      if (entity.type === "1") {
        currentListHeight = HEIGHTS.MEDIUM;
      } else if (entity.type === "2") {
        filter = {
          attribute: "companyProfile.company_id",
          value: entity.id,
          operator: "equals"
        };

        currentListHeight = HEIGHTS.LOW;
      }
    }

    console.log("--- new filter:", filter);
    this.state = {
      selectedJob: null,
      selectedCompanyProfileUrl: null,
      spotsFinishedLoadingAction: this.getLayersFinishedLoadingAction(),
      currentCameraFlyToPlaceIndex: 0,
      cameraTimer: null,
      userInteractedWithMap: false,
      cameraActive: false,
      currentListHeight,
      searchString: "",
      spotLayers: {},
      filter,
      mapOptions: {
        styleId: null,
        centerLat: 48.396,
        centerLng: 9.998,
        zoom: 13.1,
        pitch: 30,
        bearing: 0,
        minZoom: 12,
        maxZoom: 19
      },
      currentMapBounds: null,
      renderedPOIUUIDs: []
    };
  }

  normalize = string => {
    return string.trim().toLowerCase();
  };

  onCloseList = e => {
    this.setState({
      listStateWasClosed: false,
      currentListHeight: HEIGHTS.DEFAULT,
      filter: null
    });
  };

  onShowList = e => {
    this.setState({
      listStateWasClosed: false,
      currentListHeight: HEIGHTS.MEDIUM
    });
  };

  onToggleList = e => {
    let listStateWasClosed = false;
    let selectedCompanyProfileUrl = null;
    let selectedJob = null;
    let currentListHeight = HEIGHTS.MEDIUM;
    if (this.state.currentListHeight === HEIGHTS.MEDIUM) {
      currentListHeight = HEIGHTS.DEFAULT;
    }
    if (!!this.state.selectedCompanyProfileUrl || !!this.state.selectedJob) {
      currentListHeight = HEIGHTS.MEDIUM;
    }
    this.setState({
      listStateWasClosed,
      selectedCompanyProfileUrl,
      selectedJob,
      currentListHeight
    });
    this.props.onSpotPopupClose();
  };

  onListFeatureClick = feature => {
    // this.onCloseList();
    console.log("--- onListFeatureClick", feature);
    this.setState({
      cameraActive: false
    });
    this.onClickedFeature(feature.properties.uuid);
  };

  onCameraButtonPressed = e => {
    if (this.state.cameraActive === true) {
      clearTimeout(this.state.cameraTimer);
      this.setState({
        cameraActive: false,
        userInteractedWithMap: false
      });
      this.map.jumpTo({
        bearing: 0,
        pitch: 0
      });
    } else {
      this.startCamera();
    }
  };

  openPOIPopup;

  startCamera = () => {
    const { spots, selectedFeature, onSpotPopupOpen } = this.props;

    this.setState({ cameraActive: true });

    // console.log("startCamera", spots.data);
    clearTimeout(this.state.cameraTimer);

    if (this.state.userInteractedWithMap === true) {
      return;
    }

    let allFeatures = [];
    for (const spotLayer of spots.data) {
      allFeatures = [...allFeatures, ...spotLayer.featureCollection.features];
    }

    if (allFeatures.length === 0) {
      return;
    }

    if (this.state.currentCameraFlyToPlaceIndex > allFeatures.length - 1) {
      this.setState({
        currentCameraFlyToPlaceIndex: 0
      });
    }

    const flyToSpot = allFeatures[this.state.currentCameraFlyToPlaceIndex];
    let pitch = Math.random() * 60;
    let offset = [0, 0];
    let bearing = 0 + (Math.random() - 0.5) * 90;
    let zoom = 16 + Math.random() * 3;
    // if spot popup is open center the point above the spot popup and set pitch to 90 deg.
    if (selectedFeature !== null) {
      // spot popup covers 70% of screen, so shift spot up to make it visible
      const mapHeight = this.map.getCanvas().height;
      offset = [0, -(mapHeight * 0.5) * 0.35];
      pitch = 0;
      bearing = 0;
      zoom = 17;
      onSpotPopupOpen(flyToSpot.uuid);
    }

    this.map.flyTo({
      bearing,
      center: flyToSpot.geometry.coordinates,
      zoom,
      pitch,
      offset,
      speed: 0.5
    });

    this.map.once("moveend", () => {
      this.setState({
        cameraTimer: setTimeout(() => {
          // console.log("Fly to next place");
          this.setState({
            currentCameraFlyToPlaceIndex:
              this.state.currentCameraFlyToPlaceIndex + 1
          });
          if (this.state.cameraActive === true) {
            this.startCamera();
          }
        }, 3000)
      });

      // this.state.cameraTimer = setTimeout(() => {
      //   console.log("Fly to next place");
      //   this.setState({
      //     currentCameraFlyToPlaceIndex: this.state.currentCameraFlyToPlaceIndex + 1
      //   });
      //   if (this.state.cameraActive === true) {
      //     this.startCamera();
      //   }
      // }, 3000);
    });
  };

  addLayersToMap = (map, layers = []) => {
    const styleLayers = map.getStyle().layers;

    for (const layer of layers) {
      for (const styleLayer of styleLayers) {
        if (layer.insertAfterLayerId) {
          if (styleLayer.id === layer.insertAfterLayerId) {
            map.addLayer(layer, layer.insertAfterLayerId);
            break;
          }
        } else {
          map.addLayer(layer);
          break;
        }
      }
    }
  };

  addSpotLayer = spotLayer => {
    // console.log("--- add spot layer: ", spotLayer);
    const name = spotLayer.name
      ? spotLayer.name
      : Math.random()
          .toString(36)
          .substring(7);

    const featureCollection = spotLayer.featureCollection;

    if (this.map.getSource(name) !== undefined) {
      this.map.getSource(name).setData(featureCollection);
      this.startUpdateMarkersTimer(1000);
      return;
    }
    // add spots
    this.map.addSource(name, {
      type: "geojson",
      data: featureCollection,
      cluster: true,
      clusterMaxZoom: 20, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });

    // add clusters circles
    this.map.addLayer({
      id: `clusters`,
      type: "circle",
      source: name,
      filter: ["has", "point_count"],
      paint: {
        // Use step expressions (https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        "circle-stroke-width": 4,
        "circle-stroke-color": THEME.colors.cluster_border,
        "circle-color": [
          "step",
          ["get", "point_count"],
          THEME.colors.cluster,
          20,
          THEME.colors.cluster
        ],
        "circle-radius": ["step", ["get", "point_count"], 30, 20, 30]
      }
    });

    // add clusters labels
    this.map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: name,
      filter: ["has", "point_count"],
      paint: {
        "text-color": "#ffffff"
      },
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["Arial Unicode MS Bold", "DIN Offc Pro Medium"],
        "text-size": 18
      }
    });

    // unclustered points
    this.map.addLayer({
      id: "unclustered-point",
      layerName: name,
      type: "circle",
      source: name,
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-opacity": 0,
        "circle-color": "#FF0000",
        "circle-translate": [0, 0],
        "circle-translate-anchor": "map",
        // make circles larger as the user zooms from z12 to z22
        "circle-radius": 30,
        // "circle-radius": {
        //   base: 1.75,
        //   type: "exponential",
        //   stops: [[12, 30], [22, 30]]
        // },
        "circle-stroke-opacity": 0,
        "circle-stroke-width": 0,
        "circle-stroke-color": "#f00"
      }
    });

    // unclustered points labels
    // this.map.addLayer({
    //   id: "unclustered-point-text",
    //   type: "symbol",
    //   source: name,
    //   filter: ["!", ["has", "point_count"]],
    //   paint: {
    //     "text-opacity": 0,
    //     "text-color": "#ffffff"
    //   },
    //   layout: {
    //     "icon-image": "{picture_url_thumb}",
    //     "text-field": "Text: {picture_url_thumb}",
    //     "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    //     "text-size": 12
    //   }
    // });

    // Inspect a cluster on click
    this.map.on("click", "clusters", e => {
      var features = this.map.queryRenderedFeatures(e.point, {
        layers: ["clusters"]
      });
      const cluster = features[0];
      var clusterId = cluster.properties.cluster_id;
      this.map
        .getSource(name)
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }
          if (this.map.getZoom() > 17) {
            console.log("--- cluster click > 16");
            this.onClickedCluster(name, cluster);
          } else {
            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
        });
    });

    // Hove states
    this.map.on("mouseenter", "clusters", () => {
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseleave", "clusters", () => {
      this.map.getCanvas().style.cursor = "";
    });

    this.startUpdateMarkersTimer(1000);
  };

  startUpdateMarkersTimer = (msecs = 500) => {
    clearTimeout(this.updateMarkersTimer);
    this.updateMarkersTimer = setTimeout(this.updateMarkers, msecs);
  };

  getMarkerForFeature = feature => {
    let marker = null;

    if (
      feature.source !== null &&
      feature.source !== undefined &&
      this.props.config !== null
    ) {
      const url = this.spotLayerHandlerNameToUrl[feature.source];
      const handler = this.spotLayerHandler[url];
      if (typeof handler["getMarker"] == "function") {
        marker = handler["getMarker"]();
      }
    }

    if (marker === null || marker === undefined) {
      marker = UEMarker;
    }

    // this.props.onSpotPopupOpen(e.features[0]);

    const newMarker = marker.fromFeature(feature, e =>
      this.onClickedFeature(feature.properties.uuid)
    );

    return newMarker;
  };

  onListResize = size => {
    // console.log("--- onListResize", size);
    this.setState({
      currentListHeight: size
    });
  };

  onOverlayResize = size => {
    // console.log("--- onListResize", size);
    this.setState({
      currentOverlayHeight: size
    });
  };

  onSearchInputFocus = () => {
    // if (this.state.currentListHeight === HEIGHTS.DEFAULT) {
    //   this.onListResize(HEIGHTS.LOW);
    // }
    this.setState({
      currentListHeight: HEIGHTS.LOW,
      selectedCompanyProfileUrl: null,
      selectedJob: null
    });
    this.props.onSpotPopupClose();
  };

  onBackToMapAction = () => {
    console.log("--- onBackToMapAction: ");
    this.setState({
      selectedJob: null,
      selectedCompanyProfileUrl: null
    });
  };

  // --------------- SPIDERFY
  removeSpiderfyFeatures = () => {
    this.spiderfiedMarkers.forEach(marker => {
      marker.remove();
    })
    this.spiderfiedMarkers = [];
  }

  spiderfyFeaturesAtCluster = (cluster, features) => {
    this.removeSpiderfyFeatures();
    const CIRCLE_OPTIONS = {
      distanceBetweenPoints: 60
    };

    function generateEquidistantPointsInCircle({
      totalPoints = 1,
      options = CIRCLE_OPTIONS
    }) {
      let points = [];
      let theta = (Math.PI * 2) / totalPoints;
      let angle = theta;
      for (let i = 0; i < totalPoints; i++) {
        angle = theta * i;
        points.push({
          x: options.distanceBetweenPoints * Math.cos(angle),
          y: options.distanceBetweenPoints * Math.sin(angle)
        });
      }
      return points;
    }

    function generateLeavesCoordinates({ nbOfLeaves }) {
      return generateEquidistantPointsInCircle({
        totalPoints: nbOfLeaves
      });
    }

    let leavesCoordinates = generateLeavesCoordinates({
      nbOfLeaves: features.length
    });

    const spiderifiedCluster = {
      id: cluster.properties.cluster_id,
      coordinates: cluster.geometry.coordinates
    };
    let clusterXY = this.map.project(spiderifiedCluster.coordinates);

    // Generate spiderlegs and leaves coordinates
    features.forEach((feature, index) => {
      let spiderLeafLatLng = this.map.unproject([
        clusterXY.x + leavesCoordinates[index].x,
        clusterXY.y + leavesCoordinates[index].y
      ]);

      feature.source = cluster.source;
      this.spiderfiedMarkers.push(
          this.getMarkerForFeature(feature).setLngLat(spiderLeafLatLng)
        );
    });

    this.spiderfiedMarkers.forEach(marker => marker.addTo(this.map));
  };
  //------------- SPIDERFY END

  onClickedCluster = (sourceName, cluster) => {
    console.log("--- onClickedCluster", cluster);
    this.map
      .getSource(sourceName)
      .getClusterLeaves(
        cluster.properties.cluster_id,
        cluster.properties.point_count,
        0,
        (error, children) => {
          console.log("--- onClickedCluster: children", children);
          this.spiderfyFeaturesAtCluster(cluster, children);
        }
      );
  };

  onClickedFeature = featureUUID => {
    console.log("--- onClickedFeature", featureUUID);
    // Check if currently a job is openend in an inline browser, if yes then close it:
    if (!!this.state.selectedJob || !!this.state.selectedCompanyProfileUrl) {
      this.onBackToMapAction();
    }

    this.setState({
      listStateWasClosed: this.state.currentListHeight === HEIGHTS.DEFAULT,
      currentListHeight: HEIGHTS.MEDIUM
    });
    this.props.onSpotPopupOpen(featureUUID);
  };

  updateMarkers = e => {
    // let currentPoint = e.point;
    // console.log("updateMarkers for point", currentPoint);
    // var features = this.map.queryRenderedFeatures(currentPoint, { layers: ['unclustered-point'] });

    const clusteredLayerName = "clusters";
    const unclusteredLayerName = "unclustered-point";
    if (
      this.map === undefined ||
      this.map.getLayer(unclusteredLayerName) === undefined
    ) {
      // console.log("--- updateMarkers: map not ready");
      return;
    }

    // now add new markers and remove markers out of bounds
    var unclusteredFeatures = this.map.queryRenderedFeatures({
      layers: [unclusteredLayerName]
    });
    if (unclusteredFeatures === undefined) {
      // console.log("features undefined");
      return;
    } else if (unclusteredFeatures.length === 0) {
      // console.log("--- features length === 0");
      // return;
    }

    // get all visible unclustered markers
    let visibleSpotIds = {};
    for (const feature of unclusteredFeatures) {
      const spotId = feature.properties.uuid;
      visibleSpotIds[`${spotId}`] = true;
      // console.log("--- rendered feature", feature);
    }

    // remove markers outside map bounds
    for (const spotId in this.markers) {
      if (!(spotId in visibleSpotIds)) {
        // console.log("spot should be removed", spotId);
        this.markers[spotId].remove();
        delete this.markers[spotId];
      }
    }

    // add markers that are now inside the map bounds
    for (const feature of unclusteredFeatures) {
      // console.log("--- for feature of features", feature);
      const spotId = feature.properties.uuid;
      const marker = this.markers[`${spotId}`];

      // add new markers
      if (marker === undefined) {
        const m = this.getMarkerForFeature(feature);
        m.addTo(this.map);
        this.markers[`${spotId}`] = m;
      } else {
        // console.log("marker already present", marker);
      }
    }

    // get all rendered pois, clustered and unlcustered and save them in state
    let renderedUnclusteredIds = [];
    let renderedClusterFeatures = [];
    this.map
      .queryRenderedFeatures({
        layers: [clusteredLayerName, unclusteredLayerName]
      })
      .map(feature => {
        // console.log("all feature", feature);
        if (feature.properties["cluster_id"] !== undefined) {
          // if feature is a cluster add cluster_id and perform async leave query
          renderedClusterFeatures.push(feature);
        } else {
          // feature is no cluster
          renderedUnclusteredIds.push(feature.properties.uuid);
        }
      });
    this.updateRenderedPOIUUIDs(
      renderedUnclusteredIds,
      renderedClusterFeatures
    );
  };

  updateRenderedPOIUUIDs = (
    renderedUnclusteredIds,
    renderedClusterFeatures
  ) => {
    // console.log(
    //   `--- renderedUnclusteredIds.length: ${
    //     renderedUnclusteredIds.length
    //   }, renderedClusterFeatures.length: ${renderedClusterFeatures.length}`
    // );
    let poisOnMap = [];
    let remainingClusterFeatures = [...renderedClusterFeatures];
    const queryNextCluster = clusterFeatures => {
      let nextClusterFeature = null;
      if (clusterFeatures.length > 0) {
        nextClusterFeature = clusterFeatures[clusterFeatures.length - 1];
      }
      if (nextClusterFeature === null) {
        return;
      }

      const clusterId = nextClusterFeature.properties["cluster_id"];
      const pointCount = nextClusterFeature.properties["point_count"];
      let source = this.map.getSource(nextClusterFeature.source);
      source.getClusterLeaves(clusterId, pointCount, 0, (err, aFeatures) => {
        // console.log('getClusterLeaves', err, aFeatures);
        const foundFeatureUUIDs = aFeatures.map(f => {
          // console.log("--- pushing leave ", f.properties.uuid);
          return f.properties.uuid;
        });
        // remove this element
        clusterFeatures.splice(-1, 1);
        poisOnMap = [...poisOnMap, ...foundFeatureUUIDs];

        if (clusterFeatures.length === 0) {
          this.setState(
            {
              renderedPOIUUIDs: [...renderedUnclusteredIds, ...poisOnMap]
            },
            () => {
              // console.log(
              //   "--- this.state.renderedPOIUUIDs.length",
              //   this.state.renderedPOIUUIDs.length
              // );
            }
          );
        } else {
          queryNextCluster(clusterFeatures);
        }
      });
    };

    if (remainingClusterFeatures.length === 0) {
      this.setState(
        {
          renderedPOIUUIDs: [...renderedUnclusteredIds]
        },
        () => {
          // console.log(
          //   "--- this.state.renderedPOIUUIDs.length",
          //   this.state.renderedPOIUUIDs.length
          // );
        }
      );
    } else {
      queryNextCluster(remainingClusterFeatures);
    }
  };

  // each feature gets a searchResult object with info about matched search terms and ranking
  filterFeature = feature => {
    feature = SMKHelpers.applyFilterOnFeature(feature, this.state.filter);
    return SMKHelpers.applySearchFilterOnFeature(
      feature,
      this.state.searchString
    );
  };

  addOrUpdateSpotLayers = spotLayers => {
    for (const spotLayer of spotLayers) {
      let updatedSpotLayer = { ...spotLayer };
      let filteredFeatures = updatedSpotLayer.featureCollection.features.map(
        feature => {
          return this.filterFeature(feature);
        }
      );

      filteredFeatures = filteredFeatures.filter(feature => {
        return (
          feature.properties.searchResults.passed === true &&
          feature.properties.filter.passed === true
        );
      });

      updatedSpotLayer.featureCollection = { ...spotLayer.featureCollection };
      updatedSpotLayer.featureCollection.features = [...filteredFeatures];

      this.addSpotLayer(updatedSpotLayer);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // console.log("--- componentDidUpdate");
    // Typical usage (don't forget to compare props):
    if (this.props.config !== prevProps.config) {
      this.initializeMap();
      this.initializePlugins();
    }

    let refreshSpotLayers = false;
    if (prevState.searchString !== this.state.searchString) {
      refreshSpotLayers = true;
    }
    if (prevState.filter !== this.state.filter) {
      refreshSpotLayers = true;
    }
    if (prevProps.spots.meta.lastChange !== this.props.spots.meta.lastChange) {
      refreshSpotLayers = true;
    }

    if (refreshSpotLayers === true) {
      this.addOrUpdateSpotLayers(this.props.spots.data);
    }

    if (
      this.state.spotsFinishedLoadingAction &&
      this.props.spots.meta.lastChange !== 0 &&
      this.props.spots.meta.lastChange !== prevProps.spots.meta.lastChange
    ) {
      console.log(
        "--- openEntity componentDidUpdate now action",
        this.props.spots
      );
      this.performSpotsFinishedLoadingAction(
        this.state.spotsFinishedLoadingAction
      );
    }
  }

  findSpotInSpotLayer = (spotId, spotLayerName) => {
    for (const spotLayer of this.props.spots.data) {
      if (spotLayer.name === spotLayerName || spotLayerName === undefined) {
        for (const feature of spotLayer.featureCollection.features) {
          if (String(feature.properties.uuid) === String(spotId)) {
            return feature;
          }
        }
      }
    }
  };

  performSpotsFinishedLoadingAction = action => {
    console.log("--- performSpotsFinishedLoadingAction:", action);
    switch (action.type) {
      case "openEntity":
        console.log(`--- openEntity`, action);
        // this.props.onSpotPopupOpen(feature.uuid);
        const job = SMKHelpers.findJobInSpotLayerByProffileJobId(
          action.entity.id,
          this.props.spots.data,
          queryString.parse(this.props.location.search)
        );

        if (!job) {
          break;
        }
        const companyFeature = SMKHelpers.findCompanyByJobInFeatures(
          job,
          this.props.spots.data
        );
        if (!companyFeature) {
          console.log("--- found job: no company found", job);
          break;
        }
        console.log("--- found job and flyTo", job);
        this.onSetSelectedJobURL(job);
        this.onListFeatureClick(companyFeature);

        this.map.flyTo({
          center: [
            companyFeature.properties.location.geo_x,
            companyFeature.properties.location.geo_y
          ],
          offset: [
            0,
            -this.attachMapControlsDomRef.current.clientHeight / 2 + 30
          ],
          zoom: 18,
          speed: 2
        });
        this.setState({
          spotsFinishedLoadingAction: null
        });
        break;
      case "fitAll":
        console.log("--- perform fitAll");
        this.setState({
          spotsFinishedLoadingAction: null
        });

        setTimeout(() => {
          this.onZoomToFitAllPOIs({
            padding: {
              top: 0,
              right: 0,
              bottom: this.attachMapControlsDomRef.current.clientHeight,
              left: 0
            },
            animate: true
          });
        }, 2000);
        break;
      default:
        break;
    }
  };

  loadSpotLayerHandler = async spotLayer => {
    const url = spotLayer.layerHandlerURL;
    if (url !== null && url !== undefined) {
      // console.log("--- loading layerHandlerURL", url);
      return import(`../../plugins/${url}`).then(myModule => {
        // console.log("loaded layerHandlerURL", myModule);
        // console.log("--- this.spotLayerHandler", url);
        this.spotLayerHandlerNameToUrl[spotLayer.name] = url;
        this.spotLayerHandler[url] = new myModule.default(spotLayer);
      });
    }
  };

  fetchSpotLayer = async spotLayer => {
    // hast spotlayer a specific handler?
    let layerHandler = null; // <- set this to default uebermaps layer handler
    const url = spotLayer.layerHandlerURL;
    if (url !== undefined) {
      // is handler already loaded?
      if (url in this.spotLayerHandler) {
        layerHandler = this.spotLayerHandler[url];
      } else {
        // load layer handler
        await this.loadSpotLayerHandler(spotLayer);
        layerHandler = this.spotLayerHandler[url];
      }
    }

    // get must return an object containing a {"spotLayer", "meta", "data"}
    // where "data" is an array of features;
    layerHandler.get(this.gotNewSpotLayerFeatures);
  };

  gotNewSpotLayerFeatures = (spotLayer, features) => {
    // console.log("--- here is the new data", features);
    this.props.onReceivedSpotLayerFeatures(spotLayer, features);
  };

  componentDidMount() {
    this.props.getConfig();
  }

  initializePlugins = () => {
    // const { config } = this.props;
  };

  updateCurrentMapBounds = () => {
    this.setState({
      currentMapBounds: this.map.getBounds()
    });
  };

  onSetCompanyWebProfile = (feature = null) => {
    if (
      !!feature &&
      !!feature.properties &&
      !!feature.properties.companyProfile.url_profile
    ) {
      this.setState({
        selectedCompanyProfileUrl: feature.properties.companyProfile.url_profile
      });
    } else {
      this.setState({
        selectedCompanyProfileUrl: null
      });
    }
  };

  onSetSelectedJobURL = (job = null) => {
    if (!!job) {
      this.setState({
        selectedJob: job
      });
    } else {
      this.setState({
        selectedJob: null
      });
    }
  };

  onZoomToFitAllPOIs = (options = {}) => {
    let bounds = new mapboxgl.LngLatBounds();

    this.props.spots.data.forEach(layer => {
      layer.featureCollection.features.forEach(feature => {
        if (feature.properties.filter && feature.properties.filter.passed) {
          bounds.extend(feature.geometry.coordinates);
        }
      });
    });

    this.map.fitBounds(bounds, options);
  };

  initializeMap = () => {
    const { onGeolocateUpdate, onGeolocateEnd, config } = this.props;

    const parsedParams = queryString.parse(this.props.location.search);
    const mapOptions = {
      styleId: config.mapOptions.styleId,
      centerLat: Number.parseFloat(
        parsedParams.centerLat || config.mapOptions.centerLat || 48.396
      ),
      centerLng: Number.parseFloat(
        parsedParams.centerLng || config.mapOptions.centerLng || 9.998
      ),
      zoom: Number.parseFloat(
        parsedParams.zoom || config.mapOptions.zoom || 13.1
      ),
      minZoom: Number.parseFloat(
        parsedParams.minZoom || config.mapOptions.minZoom || 0
      ),
      maxZoom: Number.parseFloat(
        parsedParams.maxZoom || config.mapOptions.maxZoom || 22
      ),
      pitch: Number.parseFloat(
        parsedParams.pitch || config.mapOptions.pitch || 0
      ),
      bearing: Number.parseFloat(
        parsedParams.bearing || config.mapOptions.bearing || 0
      ),
      defaultControls: config.mapOptions.useDefaultControls || true
    };
    if (config.mapOptions.bounds !== undefined) {
      // console.log("--- we have bounds in config", config.mapOptions.bounds);
      mapOptions.bounds = config.mapOptions.bounds;
    }

    this.setState({
      mapOptions
    });

    mapboxgl.accessToken = config.mapboxAccessToken;

    this.map = new mapboxgl.Map({
      attributionControl: false,
      container: this.mapContainerRef.current,
      style: `mapbox://styles/${mapOptions.styleId}`, // use mapbox://styles/mcloud79/cjr687fzu3a2z2slf2vztmtfl if building below overlay
      center: [mapOptions.centerLng, mapOptions.centerLat],
      zoom: mapOptions.zoom,
      minZoom: mapOptions.minZoom,
      maxZoom: mapOptions.maxZoom,
      pitch: mapOptions.pitch,
      bounds: mapOptions.bounds
    });

    // |-----START: Map controls - setup:
    // |
    // Custom controls - handling
    // this.map.addControl(new mapboxgl.FullscreenControl());
    // this.map.addControl(geolocate, "bottom-right");
    // this.map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    this.map.addControl(
      new mapboxgl.AttributionControl({
        compact: true
      })
    );

    if (mapOptions.defaultControls) {
      const mapboxNavigationControls = new mapboxgl.NavigationControl();
      const mapboxLocationControls = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      });

      if (this.attachMapControlsDomRef) {
        this.attachMapControlsDomRef.current.appendChild(
          mapboxNavigationControls.onAdd(this.map)
        );
        this.attachMapControlsDomRef.current.appendChild(
          mapboxLocationControls.onAdd(this.map)
        );
      } else {
        this.map.addControl(mapboxNavigationControls);
        this.map.addControl(mapboxLocationControls);
      }
    }
    // |
    // |-----END: Map controls - setup:

    const scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: "imperial"
    });
    this.map.addControl(scale);

    scale.setUnit("metric");

    let language = new MapboxLanguage();
    this.map.addControl(language);

    this.map.on("moveend", e => {
      // console.log("moveend", this.map.getCenter());
      console.log("--- current bounds", this.map.getBounds());
      this.startUpdateMarkersTimer();
      this.removeSpiderfyFeatures();
    });

    this.map.on("touchend", e => {
      this.startUpdateMarkersTimer();
    });

    // this.map.on("dragend", e => {
    //   console.log("dragend");
    //   this.updateMarkers(e);
    // });

    this.map.on("zoomend", e => {
      this.startUpdateMarkersTimer();
      // console.log("--- zoom", this.map.getZoom());
      const allClusters = this.map
      .queryRenderedFeatures({
        layers: ["clusters"]
      })
      
      // console.log("--- how many clusters", allClusters.length);
      if (allClusters.length === 1) {
        // console.log("--- how many clusters: 1", allClusters[0]);
        const cluster = allClusters[0];
        this.onClickedCluster(cluster.source, cluster);
      }
    });

    this.map.on("resize", e => {
      this.startUpdateMarkersTimer();
    });

    this.map.on("rotateend", e => {
      this.startUpdateMarkersTimer();
    });

    this.map.on("pitchend", e => {
      this.startUpdateMarkersTimer();
    });

    this.map.on("click", e => {
      e.preventDefault();
      this.removeSpiderfyFeatures();
    });

    // this.map.on("render", e => {
    //   console.log("render");
    //   this.updateMarkers(e);
    // });

    this.map.on("load", () => {
      if (config !== null) {
        config.spotLayers.forEach(spotLayer => {
          this.fetchSpotLayer(spotLayer);
        });

        this.addLayersToMap(this.map, config.layers);
      }

      // Add fullscreen control
      // this.map.addControl(new mapboxgl.FullscreenControl());

      // Add geolocate control to the map.
      const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      });
      // this.map.addControl(geolocate, "bottom-right");
      // this.map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

      geolocate.on("geolocate", e => {
        onGeolocateUpdate(e);
      });

      geolocate.on("trackuserlocationstart", e => {});

      geolocate.on("trackuserlocationend", e => {
        onGeolocateEnd(e);
      });
    });
  };

  searchInputDidChange = searchString => {
    this.setState({
      searchString
    });
  };

  onClosePOIPopup = e => {
    if (this.state.listStateWasClosed === true) {
      this.onCloseList();
    }
    this.props.onSpotPopupClose();
  };

  isLoadedInIframe() {
    return window.location !== window.parent.location;
  }

  getMapUi() {
    return (this.props.config && this.props.config.ui) || null;
  }

  render() {
    const { config, spots, selectedUUID, geolocatePosition } = this.props;
    const {
      currentListHeight,
      searchString,
      filter,
      renderedPOIUUIDs,
      listStateWasClosed,
      selectedCompanyProfileUrl,
      selectedJob
    } = this.state;

    let allFeatures = [];
    if (spots.data !== null && spots.data.length > 0) {
      for (const spotLayer of spots.data) {
        allFeatures = [...allFeatures, ...spotLayer.featureCollection.features];
      }
      let filteredFeatures = allFeatures.map(feature => {
        return this.filterFeature(feature);
      });
      filteredFeatures = filteredFeatures.filter(feature => {
        return (
          feature.properties.searchResults.passed === true &&
          feature.properties.filter.passed === true
        );
      });
      allFeatures = [...filteredFeatures];
    }

    let plugins = [];
    if (!!config && config.plugins) {
      for (const plugin of config.plugins) {
        plugins.push(
          React.createElement(eval(plugin.name), { key: plugin.name, plugin })
        );
      }
    }

    const feature = !!selectedUUID && this.findSpotInSpotLayer(selectedUUID);

    let inlineBrowserURL = null;
    let inlineBrowserCloseCallback = null;
    let inlineBrowserOptions = {};
    if (!!selectedJob) {
      inlineBrowserURL = selectedJob.original_url;
      inlineBrowserCloseCallback = this.onSetSelectedJobURL;
      inlineBrowserOptions = { job: selectedJob };
    } else if (!!selectedCompanyProfileUrl) {
      inlineBrowserURL = selectedCompanyProfileUrl;
      inlineBrowserCloseCallback = this.onSetCompanyWebProfile;
    }
    console.log("C:Map|M:render - inlineBrowserURL: ", inlineBrowserURL);

    return (
      <Div100vh>
        <MapContainer>
          <MapLogo
            href="https://www.proffile.de/impressum-datenschutz.html"
            target="_blank"
            alt="Proffile Map - Impressum"
          />
          {!!config && <MapboxWrapper ref={this.mapContainerRef} />}
          {/* <SearchInput
            placeholder="Jobs durchsuchen..."
            value={this.state.searchString}
            onChange={this.searchInputDidChange}
          /> */}
          <div className="mapboxgl-ctrl-top-left--custom">
            {/*
              <button className="cameraButton bg-white ma0" onClick={this.onCameraButtonPressed}>
                <span className="oi" data-glyph={this.state.cameraActive ? "media-stop" : "media-play"} />
              </button>
              */}
            <MapControl>
              <MapControlButton
                className="ToggleListButton"
                onClick={this.onToggleList}
              >
                <FontAwesomeIcon className="resizerIcon" icon={faList} />
              </MapControlButton>
            </MapControl>
          </div>
          {plugins}
          <ResizeableContentArea
            ref={this.attachMapControlsDomRef}
            height={this.state.currentListHeight}
            onResize={this.onListResize}
            hasInlineBrowser={!!inlineBrowserURL}
          >
            {(feature && (
              <POIPopup
                selectedFeature={feature}
                onFeatureClick={this.onListFeatureClick}
                onCompanyWebProfileClick={this.onSetCompanyWebProfile}
                onJobClick={this.onSetSelectedJobURL}
                onSpotPopupClose={this.onClosePOIPopup}
                listStateWasClosed={listStateWasClosed}
                geolocatePosition={geolocatePosition}
                searchString={searchString}
                isInlineBrowserActive={!!inlineBrowserURL}
              />
            )) || (
              <POIList
                allPOIs={allFeatures}
                renderedPOIUUIDs={renderedPOIUUIDs}
                onZoomToFitAllPOIs={this.onZoomToFitAllPOIs}
                onCompanyWebProfileClick={this.onSetCompanyWebProfile}
                onFeatureClick={this.onListFeatureClick}
                onJobClick={this.onSetSelectedJobURL}
                onSearchInputFocus={this.onSearchInputFocus}
                onClose={this.onCloseList}
                searchString={searchString}
                handleSearchUpdate={this.searchInputDidChange}
                headerTitle={!!filter ? "Alle" : "Karte"}
                isInlineBrowserActive={!!inlineBrowserURL}
              />
            )}
            {!!inlineBrowserURL && (
              <InlineBrowser
                url={inlineBrowserURL}
                handleClose={inlineBrowserCloseCallback}
                options={inlineBrowserOptions}
                isLoadedInIframe={this.isLoadedInIframe()}
              />
            )}
            {!!inlineBrowserURL && currentListHeight !== "defaultHeight" && (
              <StickyOverlay options={inlineBrowserOptions} />
            )}
          </ResizeableContentArea>
          {/* {!!inlineBrowserURL && (
            <ResizeableContentArea
              height={this.state.currentOverlayHeight}
              onResize={this.onOverlayResize}
              initialSize={3}
            >
              <InlineBrowser
                url={inlineBrowserURL}
                handleClose={inlineBrowserCloseCallback}
                options={inlineBrowserOptions}
                isLoadedInIframe={this.isLoadedInIframe()}
              />
            </ResizeableContentArea>
          )} */}
        </MapContainer>
        {this.isLoadedInIframe() === false && (
          <Menu
            backToMapVisible={
              !!this.state.selectedCompanyProfileUrl || !!this.state.selectedJob
            }
            mapUi={this.getMapUi()}
            backToMapAction={this.onBackToMapAction}
            focusSearchInputAction={this.onSearchInputFocus}
            toggleListAction={this.onToggleList}
          />
        )}
      </Div100vh>
    );
  }
}

function mapStateToProps(state) {
  return {
    config: state.config.data,
    spots: state.spots,
    selectedFeature: state.spotPopup.selectedFeature,
    selectedUUID: state.spotPopup.selectedUUID,
    geolocatePosition: state.geolocate.position
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getConfig: () => {
      const { configId } = ownProps.match.params;
      const project = "proffile";
      const configFolder = "maps";
      console.log("--- configId", configId);
      if (!!project) {
        if (!!configFolder) {
          if (!!configId) {
            dispatch(getConfig(`${project}/${configFolder}/${configId}`));
          } else {
            dispatch(getConfig(`${project}/${configFolder}/default`));
          }
        }
      }
    },
    onReceivedSpotLayerFeatures: (spotLayer, features) => {
      dispatch(receivedSpotLayerFeatures(spotLayer, features));
    },
    onGeolocateUpdate: e => {
      const position = { coords: e.coords, timestamp: e.timestamp };
      // console.log("geolocate", position);
      dispatch(geolocateUpdate(position));
    },
    onGeolocateEnd: e => {
      // console.log("geolocate end", e);
      // dispatch(geolocateEnd(e));
    },
    onSpotPopupOpen: featureUUID => {
      // console.log("clicked feature", featureUUID);
      dispatch(spotPopupOpen(featureUUID));
    },
    onSpotPopupClose: () => {
      dispatch(spotPopupClose());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
