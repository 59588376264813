import React from "react";
import styled from "styled-components";
import Resizer from "./components/Resizer";

const ContentAreaWrapper = styled.div.attrs(props => ({
  className:
    " resizableContentArea bg-white absolute w-100 bottom-0 z-2 flex flex-column"
}))`
  box-sizing: border-box;
  padding-top: 35px;
  &.lowHeight {
    height: 40%;
  }
  &.mediumHeight {
    height: 70%;
  }
  &.fullHeight {
    height: calc(100% - 80px);
  }
  &.defaultHeight {
    height: 80px;
  }
  transition-property: height;
  transition-duration: 0.16s;
  transition-timing-function: ease-out;

  .mapboxgl-ctrl.mapboxgl-ctrl-group {
    position: absolute;
    right: 0.5rem;
    top: -170px;
  }
  .mapboxgl-ctrl.mapboxgl-ctrl-group ~ .mapboxgl-ctrl.mapboxgl-ctrl-group {
    position: absolute;
    right: 0.5rem;
    top: -70px;
  }
`;

export const HEIGHTS = {
  DEFAULT: "defaultHeight",
  LOW: "lowHeight",
  MEDIUM: "mediumHeight",
  FULL: "fullHeight"
};

class ResizableContentArea extends React.Component {
  onResize = size => {
    console.log("switch to size: ", HEIGHTS[size]);
    this.props.onResize(HEIGHTS[size]);
  };

  render() {
    const { height, forwaredRef, hasInlineBrowser } = this.props;
    console.log("C:ResizableContentArea|M:render - height: ", height);
    console.log(
      "C:ResizableContentArea|M:render  - Object.values(HEIGHTS).indexOf(height)",
      Object.values(HEIGHTS).indexOf(height)
    );

    return (
      <ContentAreaWrapper className={`${height} ${hasInlineBrowser ? "enlargedResizer" : ""}`} ref={forwaredRef}>
        {this.props.children}
        <Resizer
          handleResize={this.onResize}
          sizes={HEIGHTS}
          sizeIndex={Object.values(HEIGHTS).indexOf(height) || 0}
        />
      </ContentAreaWrapper>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <ResizableContentArea forwaredRef={ref} {...props} />
));
