import { 
    GET_CONFIG_REQUEST, 
    GET_CONFIG_SUCCESS, 
    GET_CONFIG_FAILURE 
} from "../../actions/config/getConfig";

const Meta = params => {
  return {
    limit: 100,
    isFetching: false,
    invalidate: false,
    ...params
  };
};

const defaultState = {
  meta: Meta(),
  data: null
};

export const configReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_CONFIG_REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          errors: null,
          isFetching: true
        }
      };
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        meta: {
          ...action.payload.meta,
          isFetching: false
        },
        data: action.payload.data
      };
    case GET_CONFIG_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          errors: action.payload.errors
        }
      };
    default:
      return state;
  }
};

// export default spotsReducer;
